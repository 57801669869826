/* 
:root {
  --color-black: #000000;
  --color-white: #ffffff;

  --font-titles: "gobold_bolditalic";
  --font-body: "codec_proregular";
  --font-body-bold: "codec_probold";
  --font-body-extraBold: "codec_proextrabold";
  --font-body-heavy: "codec_proheavy";

  
  --modal-bg: #13181e;

  --primary-color: rgb(183, 0, 255);
  --primary-color-emphasis: #cc4dff;
  --primary-color-dark: #662680;
  --secondary-color: rgb(62, 75, 90);
  --secondary-color-emphasis: #3e4b5a;
  --secondary-color-emphasis-rgb: 62, 75, 90;
  --accent-color: #ff9933;
  --dashboard-bg: #13181e;
  --body-color: #aaaaaa;
  --dark-color: #181619;
  --info-color: #00c2ff;
  --gray-color: #a6a6a6;
  --golden-color: #c5a260;
  

  --bs-primary-rgb: var(--primary-color);
  --bs-secondary-rgb: var(--secondary-color);
  --bs-link-color: var(--primary-color);
  --bs-secondary-bg-subtle: #323d48;
  --bs-success-rgb: 7, 188, 12;
  --disabled:#3e4b5a79;
  --navbar-top-height: 5.25rem;
} */

:root {
  --color-black: #000000;
  --color-white: #ffffff;

  --font-titles: "Oswald Bold";
  --font-titles-regular: "Oswald Regular Regular";
  --font-body: "codec_proregular";
  --font-body-bold: "codec_probold";
  --font-body-extraBold: "codec_proextrabold";
  --font-body-heavy: "codec_proheavy";

  /* Modal */
  --modal-bg: #13181e;

  /* Custom Colors */
  --primary-color: #F92C2C;
  --primary-color-dark-blue: #070B28;
  --primary-color-red: #F92C2C;
  --primary-color-emphasis: #FFC609;
  --primary-color-dark: #FFC609;
  --secondary-color: #D9D9D9;
  --secondary-color-emphasis: #D9D9D9;
  --secondary-color-emphasis-rgb: #4C555F;
  --accent-color: #070B28;
  --accent-color-2: #FFC609;
  --dashboard-bg: #FFC609;
  --body-color: #707070;
  --dark-color: #070B28;
  --info-color: #00c2ff;
  --gray-color: #a6a6a6;
  --golden-color: #c5a260;
  --golden-color-emphasis: #cc9747;
  --color-black-2: #1C1C1C;

  /* Bootstrap Variable Override */
  --bs-primary-rgb: var(--primary-color);
  --bs-secondary-rgb: var(--secondary-color);
  --bs-link-color: var(--primary-color);
  --bs-secondary-bg-subtle: #323d48;
  --bs-success-rgb: 7, 188, 12;
  --disabled: #3e4b5a79;
  --navbar-top-height: 5.25rem;
  --premium-color:#1C1C1C;
}